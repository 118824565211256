import React from "react";
import { Route, BrowserRouter, Redirect, useLocation } from "react-router-dom";
import PublicPage from "../PublicPage";
import Admin from "layouts/Admin.js";
import { ThemeProvider } from "@material-ui/core";
import theme from "theme";
import { AuthContextProvider } from "context";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <BrowserRouter>
            <Route exact path="/" component={PublicPage} />
            <Route path="/admin" component={Admin} />
            <Route path="*">
              {!location?.pathname?.startsWith("/admin") && <Redirect to="/" />}
            </Route>
          </BrowserRouter>
        </AuthContextProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
