import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { useAuthContextDispatch } from "context";
import { loginUser } from "context";
import { useAuthState } from "context";
import { useForm } from "react-hook-form";
import { emailRegex } from "views/Usuarios/helpers/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "success",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  inputMaterial: {
    width: "100%",
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
}));

const INITIAL_STATE = {
  user: "",
  password: "",
};

const LoginBox = ({ handleClose }) => {
  const dispatch = useAuthContextDispatch();
  const classes = useStyles();
  const { error, user, loading } = useAuthState();
  const history = useHistory();

  const methods = useForm({ defaultValues: INITIAL_STATE, mode: "onSubmit" });

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods;

  useEffect(() => {
    if (user) {
      history.push("/admin/dashboard");
    }
  }, [user]);

  const onSubmit = async (data) => {
    const payload = { user: data.user, pass: data.password };
    try {
      await loginUser(dispatch, payload);
    } catch (error) {
      console.log("file: LoginBox.js ~ line 89 ~ error", error);
    }
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit((data) => onSubmit(data))}>
      <TextField
        {...register("user", {
          pattern: {
            value: emailRegex,
            message: "Usuario inválido",
          },
        })}
        className={classes.inputMaterial}
        label="Usuario"
        type="text"
      />
      <TextField
        {...register("password")}
        className={classes.inputMaterial}
        label="Password"
        type="password"
      />

      {(error || errors?.user?.message) && (
        <Alert severity="error">
          {errors?.user?.message ||
            (error === "required"
              ? "Por favor, completa todos los datos"
              : error === "unauthorized"
              ? "No tienes autorización para ingresar"
              : error === "invalid_credentials"
              ? "Al parecer las credenciales son incorrectas"
              : "Algo salio mal, intenta nuevamente")}
        </Alert>
      )}
      <div style={{ marginTop: 25 }}>
        <Button component={Link} to="/" variant="contained" onClick={handleClose}>
          Registrarse
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          style={{ minWidth: 120 }}
        >
          {loading ? <CircularProgress color="white" size={16} /> : "Ingresar"}
        </Button>
      </div>
    </form>
  );
};

export default LoginBox;
