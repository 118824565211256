import React, { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { setSyntheticLeadingComments } from "typescript";

const useStyles = makeStyles(styles);

function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    classesInputProps,
    tooltipTitle,
    disabled,
  } = props;

  // const [isFocused, setisFocused] = useState(false);

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  });
  let newInputProps = {
    maxLength: inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength: inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    step: inputProps && inputProps.step ? inputProps.step : undefined,
  };
  return (
    <Tooltip
      title={tooltipTitle ? <span style={{ fontSize: 12 }}>{tooltipTitle}</span> : ""}
      arrow
      placement="top-start"
      open={error ?? false}
      classes={styles.tootltip}
    >
      <FormControl
        {...formControlProps}
        className={formControlProps.className + " " + classes.formControl}
      >
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <Input
          // onFocus={() => setisFocused(!isFocused)}
          // onBlur={() => setisFocused(!isFocused)}
          classes={{
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses,
            ...classesInputProps,
          }}
          id={id}
          {...inputProps}
          inputProps={newInputProps}
          disabled={disabled}
        />
        {error ? (
          <Clear className={classes.feedback + " " + classes.labelRootError} />
        ) : success ? (
          <Check className={classes.feedback + " " + classes.labelRootSuccess} />
        ) : null}
      </FormControl>
    </Tooltip>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  tooltipTitle: PropTypes.string,
};

export default memo(CustomInput);
