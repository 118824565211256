import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
// import { GoogleAuthProvider } from "googleAuth";
import "assets/css/material-dashboard-react.css?v=1.10.0";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  //   <GoogleAuthProvider>
  //   <App />
  // </GoogleAuthProvider>,
  document.getElementById("root")
);
