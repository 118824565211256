import { useContext } from "react";
import { login } from "views/Login/services/login";
import { AuthontextDispatch, AuthStateContext } from "./authContext";

export const useAuthState = () => useContext(AuthStateContext);

export const useAuthContextDispatch = () => useContext(AuthontextDispatch);

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: "REQUEST_LOGIN" });

    const { response, status } = await login(loginPayload.user, loginPayload.pass);

    if (!loginPayload.user || !loginPayload.pass) {
      dispatch({ type: "LOGIN_ERROR", error: "required" });
    } else if (status === 203) {
      dispatch({ type: "LOGIN_ERROR", error: "invalid_credentials" });
    } else if (parseFloat(response?.permisos || 0) <= 1) {
      dispatch({ type: "LOGIN_ERROR", error: "unauthorized" });
    } else if (response) {
      const { codigoUsuario, user, permisos } = response;
      const sucursales = [];

      const userData = {
        codigoUsuario,
        user,
        permisos,
      };

      if (typeof response.user === "object") {
        userData.codigoUsuario = response.user.codigoUsuario;
        userData.user = response.user.user;
        userData.permisos = response.user.permisos;
      }
      if (!permisos.includes("2")) {
        for (const sucursal of Object.entries(response).filter((field) =>
          field[0].includes("permitido")
        )) {
          if (sucursal[1]) sucursales.push({ codigo: sucursal[0].split("permitido")[1] });
        }
      }
      userData.sucursales = sucursales;
      dispatch({ type: "LOGIN_SUCCESS", payload: userData });

      localStorage.setItem("currentUser", JSON.stringify(userData));

      return userData;
    } else {
      dispatch({ type: "LOGIN_ERROR", error: "unexpected" });
    }

    return;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}

export async function logout(dispatch) {
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("currentUser");
}
