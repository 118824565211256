import React, { useRef, useState } from "react";
import classNames from "classnames";
import { NavLink, useHistory } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { makeStyles } from "@material-ui/core";
import { useAuthContextDispatch } from "context";
import { useAuthState } from "context";
import { logout } from "context";

const useStyles = makeStyles(headerLinksStyle);

const HeaderLinks = () => {
  const classes = useStyles();
  const dispatch = useAuthContextDispatch();
  const history = useHistory();
  const [notificationsPopupOpen, setNotificationsPopupOpen] = useState(false);
  const [profilePopupOpen, setProfilePopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSetAnchorEl = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleNotifications = (e, clickAway) => {
    e.stopPropagation();

    !clickAway && !anchorEl && handleSetAnchorEl(e);

    setNotificationsPopupOpen((prevOpen) => !prevOpen);
    setProfilePopupOpen(false);
  };

  const handleToggleProfile = (e, clickAway) => {
    e.stopPropagation();

    !clickAway && handleSetAnchorEl(e);

    setProfilePopupOpen((prevOpen) => !prevOpen);
    setNotificationsPopupOpen(false);
  };

  const handlelogout = async () => {
    await logout(dispatch);

    history.push("/");
  };

  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  return (
    <div>
      <div className={classes.searchWrapper}>
        <h4> Hola {currentUser?.user}! </h4>
      </div>
      {/*
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Panel principal</p>
        </Hidden>
      </Button>
      <div className={classes.manager}>
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={handleToggleNotifications}
            className={classes.buttonLink}
          >
            <Notifications className={classes.icons} />
            <span className={classes.notifications}>3</span>
            <Hidden mdUp implementation="css">
              <p onClick={() => null} className={classes.linkText}>
                Notificaciones
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={notificationsPopupOpen}
            anchorEl={anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !notificationsPopupOpen }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener
                    onClickAway={(e) => handleToggleNotifications(e, true)}
                  >
                    <MenuList role="menu">
                      <MenuItem
                        onClick={handleToggleNotifications}
                        className={classes.dropdownItem}
                      >
                        Tiene mensajes sin leer
                      </MenuItem>
                      <MenuItem
                        onClick={handleToggleNotifications}
                        className={classes.dropdownItem}
                      >
                        Tiene clientes pendientes de validar documentación.
                      </MenuItem>
                      <MenuItem
                        onClick={handleToggleNotifications}
                        className={classes.dropdownItem}
                      >
                        Tiene pedidos pendientes de aprobación
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
              */}
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Person"
          aria-owns={profilePopupOpen ? "menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleToggleProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Perfil</p>
          </Hidden>
        </Button>
        <Poppers
          open={profilePopupOpen}
          anchorEl={anchorEl}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !profilePopupOpen }) +
            " " +
            classes.pooperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={(e) => handleToggleProfile(e, true)}>
                  <MenuList role="menu">
                    <NavLink to="/admin/user">
                      <MenuItem
                        onClick={handleToggleProfile}
                        className={classes.dropdownItem}
                      >
                        Perfil
                      </MenuItem>
                    </NavLink>
                    <MenuItem
                      onClick={handleToggleProfile}
                      className={classes.dropdownItem}
                    >
                      Guia de uso
                    </MenuItem>
                    <MenuItem onClick={handlelogout} className={classes.dropdownItem}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
};

export default HeaderLinks;
