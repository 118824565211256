export const mapFormDataToCreateUser = (data) => {
  const {
    user,
    password,
    isSeller,
    isAdmin,
    isRoot,
    isOperator,
    isFinancialAuthorizer,
    isComercialAuthorizer,
    isOperatorViewer,
    isBranchVMAllowed,
    isBranchBBAllowed,
    isBranchCFAllowed,
    isBranchRAAllowed,
    isBranchGPAllowed,
    isBranchRSAllowed,
  } = data;
  const permisosFirstChar = isRoot
    ? "4"
    : isAdmin
    ? "3"
    : isSeller
    ? "2"
    : isOperator
    ? "5"
    : "1";
  const permisosSecondChar =
    isComercialAuthorizer && isFinancialAuthorizer && isOperatorViewer
      ? "678"
      : isFinancialAuthorizer && isComercialAuthorizer
      ? "67"
      : isFinancialAuthorizer && isOperatorViewer
      ? "78"
      : isComercialAuthorizer && isOperatorViewer
      ? "68"
      : isFinancialAuthorizer
      ? "7"
      : isComercialAuthorizer
      ? "6"
      : isOperatorViewer
      ? "8"
      : "";

  return {
    user,
    pass: password,
    permisos: `${permisosFirstChar}${permisosSecondChar}`,
    isBranchVMAllowed,
    isBranchBBAllowed,
    isBranchCFAllowed,
    isBranchRAAllowed,
    isBranchGPAllowed,
    isBranchRSAllowed,
  };
};

export const mapFormDataToResetPass = (data) => {
  const { user, password } = data;

  return {
    user,
    pass: password,
  };
};

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$-/!%*?&])([A-Za-z\d$@$-/!%*?&]|[^ ]){8,15}$/;
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
