/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="https://www.thefuzzyfish.com/" className={classes.block}>
                Home
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://www.villanueva.com.ar/" className={classes.block}>
                Villanueva
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.villanueva.com.ar/module/clarika_pricelist_file/filelist"
                className={classes.block}
              >
                E-commerce
              </a>
            </ListItem>
          </List>
        </div>
        <p>
          <span>
            &copy; {new Date().getFullYear()}{" "}
            <a href="https://fuzzy.fish/" target="_blank" className={classes.a}>
              Fuzzy Fish
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
}
