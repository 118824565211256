import { ApiConnection } from "config";

export async function login(user, pass) {
  try {
    const { status, data } = await ApiConnection.get("users/login", {
      headers: {
        user,
        pass,
        isFromBO: "true",
      },
    });
    if (status === 200) {
      const permitidos = /(permitido)/;
      let sucursal = Object.keys(data.response)
        .filter((a) => permitidos.test(a))
        .filter((b) => data.response[b] === true)
        .map((c) => c.replace("permitido", ""));
      localStorage.setItem("permiso", data.response.permisos);
      localStorage.setItem("asignacion", sucursal);
    }
    window.location.reload();
    return { ...data, status };
  } catch (error) {
    console.log("file: login.js ~ line 5 ~ error", error);
  }
}
