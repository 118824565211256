import { lazy } from "react";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import People from "@material-ui/icons/People";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import Layers from "@material-ui/icons/Layers";
import Sync from "@material-ui/icons/Sync";
import WorkIcon from "@material-ui/icons/Work";
import AssignmentIcon from "@material-ui/icons/Assignment";
// import FindInPageIcon from "@material-ui/icons/FindInPage";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CategoryIcon from "@material-ui/icons/Category";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import LabelIcon from "@material-ui/icons/Label";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
// import InfoIcon from "@material-ui/icons/Info";
// import ApartmentIcon from "@material-ui/icons/Apartment";
// import FlagIcon from "@material-ui/icons/Flag";
// import Unarchive from "@material-ui/icons/Unarchive";

// core components/views for Admin layout
const DashboardPage = lazy(() => import("views/Dashboard/Dashboard.js"));
const Clientes = lazy(() => import("views/Clientes/Clientes.js"));
const Usuarios = lazy(() => import("views/Usuarios/Usuarios.js"));
// const Vendedores = lazy(() => import("views/Vendedores/Vendedores.js"));
const Pedidos = lazy(() => import("views/Pedidos/Pedidos.js"));
const Productos = lazy(() => import("views/Productos/Productos.js"));
// const Administrador = lazy(() => import("views/Administrador/Administrador.js"));
// const Promos = lazy(() => import("views/Promos/Promos.js"));
// const Caravanas = lazy(() => import("views/Caravanas/Caravanas.jsx"));
const Caracteristicas = lazy(() => import("views/Caracteristicas"));
const Categorias = lazy(() => import("views/Categorias"));
const AbmAtributos = lazy(() => import("views/abm_atibutos/"));
// const AbmSucursales = lazy(() => import("views/abm_sucursales/Abm_sucursales"));
const Sucursales = lazy(() => import("views/Sucursales"));
// const CatComerciales = lazy(() => import("views/CategoriasComerciales"));
// const Marca = lazy(() => import("views/Marca"));
const Productores = lazy(() => import("views/Productores"));
const Archivos = lazy(() => import("views/Archivos"));
// const Proveedores = lazy(() => import("views/Proveedores"));
// const GrupoClientes = lazy(() => import("views/GrupoClientes"));
// const Promociones = lazy(() => import("views/Promociones"));
const Descuentos = lazy(() => import("views/Descuentos/Descuentos"));
const Transportes = lazy(() => import("views/Transportes"));
// const Combos = lazy(() => import("views/Combos"));
const Noticias = lazy(() => import("views/Noticias"));
// const Banners = lazy(() => import("views/Banners"));
const Sincronizacion = lazy(() => import("views/Sincronizacion/Sincronizacion"));
// const Comprobantes = lazy(() => import("views/Comprobantes/Comprobantes"));

const level = localStorage.getItem("permiso") || "0";
const dashboardRoutes = [];
if (level.includes("4")) {
  dashboardRoutes.push(
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: Dashboard,
      component: DashboardPage,
      layout: "/admin",
    },
    // {
    //   path: "/clientes",
    //   name: "Clientes",
    //   icon: People,
    //   component: Clientes,
    //   layout: "/admin",
    // },
    {
      path: "/usuarios",
      name: "Usuarios",
      icon: Person,
      component: Usuarios,
      layout: "/admin",
    },
    // {
    //   path: "/table",
    //   name: "Vendedores",
    //   icon: WorkIcon,
    //   component: Vendedores,
    //   layout: "/admin",
    // },
    {
      path: "/Pedidos",
      name: "Pedidos",
      icon: AssignmentIcon,
      component: Pedidos,
      layout: "/admin",
    },
    {
      path: "/Productos",
      name: "Productos",
      icon: LocalMallIcon,
      component: Productos,
      layout: "/admin",
    },
    {
      path: "/categorias",
      name: "Categorias",
      icon: CategoryIcon,
      component: Categorias,
      layout: "/admin",
    },
    {
      path: "/descuentos",
      name: "Descuentos",
      icon: LocalOfferIcon,
      component: Descuentos,
      layout: "/admin",
    },
    // {
    //   path: "/atributos",
    //   name: "Atributos",
    //   icon: ColorLensIcon,
    //   component: AbmAtributos,
    //   layout: "/admin",
    // },
    {
      path: "/caracteristicas",
      name: "Características",
      icon: LabelIcon,
      component: Caracteristicas,
      layout: "/admin",
    },
    {
      path: "/sucursales",
      name: "Sucursales",
      icon: LabelIcon,
      component: Sucursales,
      layout: "/admin",
    },
    {
      path: "/cms",
      name: "CMS",
      icon: CloudUploadIcon,
      component: Archivos,
      layout: "/admin",
    },
    {
      path: "/noticias",
      name: "Noticias",
      icon: LabelIcon,
      component: Noticias,
      layout: "/admin",
    },
    {
      path: "/sincronizacion",
      name: "Sincronización",
      icon: Sync,
      component: Sincronizacion,
      layout: "/admin",
    }
  );
} else if (
  level.includes("5") ||
  level.includes("2") ||
  level.includes("6") ||
  level.includes("7") ||
  level.includes("8")
) {
  dashboardRoutes.push(
    // {
    //   path: "/dashboard",
    //   name: "Dashboard",
    //   icon: Dashboard,
    //   component: DashboardPage,
    //   layout: "/admin",
    // },
    // {
    //   path: "/clientes",
    //   name: "Clientes",
    //   icon: People,
    //   component: Clientes,
    //   layout: "/admin",
    // },
    {
      path: "/Pedidos",
      name: "Pedidos",
      icon: AssignmentIcon,
      component: Pedidos,
      layout: "/admin",
    }
    // {
    //   path: "/Productos",
    //   name: "Productos",
    //   icon: LocalMallIcon,
    //   component: Productos,
    //   layout: "/admin",
    // },
    // {
    //   path: "/descuentos",
    //   name: "Descuentos",
    //   icon: LocalOfferIcon,
    //   component: Descuentos,
    //   layout: "/admin",
    // }
  );
} else if (level.includes("3")) {
  dashboardRoutes.push(
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: Dashboard,
      component: DashboardPage,
      layout: "/admin",
    },
    {
      path: "/usuarios",
      name: "Usuarios",
      icon: Person,
      component: Usuarios,
      layout: "/admin",
    },
    // {
    //   path: "/table",
    //   name: "Vendedores",
    //   icon: WorkIcon,
    //   component: Vendedores,
    //   layout: "/admin",
    // },
    {
      path: "/Pedidos",
      name: "Pedidos",
      icon: AssignmentIcon,
      component: Pedidos,
      layout: "/admin",
    },
    {
      path: "/Productos",
      name: "Productos",
      icon: LocalMallIcon,
      component: Productos,
      layout: "/admin",
    },
    {
      path: "/categorias",
      name: "Categorias",
      icon: CategoryIcon,
      component: Categorias,
      layout: "/admin",
    },
    {
      path: "/descuentos",
      name: "Descuentos",
      icon: LocalOfferIcon,
      component: Descuentos,
      layout: "/admin",
    },
    // {
    //   path: "/atributos",
    //   name: "Atributos",
    //   icon: ColorLensIcon,
    //   component: AbmAtributos,
    //   layout: "/admin",
    // },
    {
      path: "/caracteristicas",
      name: "Características",
      icon: LabelIcon,
      component: Caracteristicas,
      layout: "/admin",
    },
    {
      path: "/sincronizacion",
      name: "Sincronización",
      icon: Sync,
      component: Sincronizacion,
      layout: "/admin",
    }
  );
}

export default dashboardRoutes;
