let user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser"))
  : "";
// let token = localStorage.getItem("currentUser")
//   ? JSON.parse(localStorage.getItem("currentUser")).auth_token
//   : "";

export const initialState = {
  user: user || null,
  // token: token || "",
  loading: false,
  error: null,
};

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload.user,
        loading: false,
        error: null,
        // token: action.payload.auth_token,
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        // token: "",
      };

    case "LOGIN_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
