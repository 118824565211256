import React from "react";
import LoginBox from "./components/LoginBox";

const PublicPage = () => {
  return (
    <div align="center">
      <h2>
        <b> Panel de administracion</b>{" "}
      </h2>
      <LoginBox />
    </div>
  );
};

export default PublicPage;
