import React, { createContext, useReducer } from "react";
import { AuthReducer, initialState } from "./authReducer";

export const AuthStateContext = createContext(null);
export const AuthontextDispatch = createContext(null);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthontextDispatch.Provider value={dispatch}>
        {children}
      </AuthontextDispatch.Provider>
    </AuthStateContext.Provider>
  );
};
